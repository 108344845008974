import { cva } from "./cva.ts"

// #region Avatar
export const Avatar = ({ className, src, variant, ...props }: AvatarProps) => (
  <img
    className={classNameForAvatar({ className, variant })}
    src={src || defaultAvatarSVG}
    loading="lazy"
    alt=""
    {...props}
  />
)

// #endregion

// #region classNames

export const classNameForAvatar = cva(
  ["bg-gray-4", "text-gray-9", "object-cover", "overflow-hidden", "-outline-offset-1"],
  {
    variants: {
      variant: {
        circle: ["rounded-full [outline:1px_solid_rgb(0_0_0/5%)]"],
        rounded: ["rounded-md"],
      },
    },
    defaultVariants: {
      variant: "circle",
    },
  },
)

// #endregion

// #region SVGs

export const defaultAvatarSVG = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath fill='%23202020' d='M0 0h8v8H0zm40 32h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8zM8 48h8v8H8zm0-24h8v8H8z'/%3E%3Cpath fill='%23646464' d='M16 48h8v8h-8zm0-24h8v8h-8zm32 8h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8zM8 0h8v8H8z'/%3E%3Cpath fill='%23808080' d='M16 0h8v8h-8zm8 48h8v8h-8zm0-24h8v8h-8zm32 8h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8z'/%3E%3Cpath fill='%238D8D8D' d='M0 16h8v8H0zm0 24h8v8H0zM24 0h8v8h-8zm8 48h8v8h-8zm0-24h8v8h-8z'/%3E%3Cpath fill='%23BBBBBB' d='M32 0h8v8h-8zm8 24h8v8h-8zm0 24h8v8h-8zM8 16h8v8H8zm0 24h8v8H8z'/%3E%3Cpath fill='%23D4D4D4' d='M16 16h8v8h-8zm0 24h8v8h-8zM40 0h8v8h-8zm8 24h8v8h-8zm0 24h8v8h-8z'/%3E%3Cpath fill='%23DDDDDD' d='M24 16h8v8h-8zm0 24h8v8h-8zM48 0h8v8h-8zm8 48h8v8h-8zm0-24h8v8h-8z'/%3E%3Cpath fill='%23E4E4E4' d='M0 32h8v8H0zm0 24h8v8H0zM0 8h8v8H0zm32 8h8v8h-8zm0 24h8v8h-8zM56 0h8v8h-8z'/%3E%3Cpath fill='%23EBEBEB' d='M40 16h8v8h-8zm0 24h8v8h-8zM8 32h8v8H8zm0 24h8v8H8zM8 8h8v8H8z'/%3E%3Cpath fill='%23F1F1F1' d='M16 32h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8zm32 32h8v8h-8zm0-24h8v8h-8z'/%3E%3Cpath fill='%23F9F9F9' d='M0 48h8v8H0zm24-16h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8zm32 8h8v8h-8zm0 24h8v8h-8z'/%3E%3Cpath fill='%23FCFCFC' d='M0 24h8v8H0zm32 8h8v8h-8zm0 24h8v8h-8zm0-48h8v8h-8z'/%3E%3C/svg%3E`

export const toInitialsSVG = (initials: string) =>
  `data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Crect width='100%25' height='100%25' fill='%23e8e8e8'/%3E%3Ctext x='50%25' y='50%25' fill='%238d8d8d' dominant-baseline='central' font-family='system-ui,sans-serif' font-size='9' font-weight='300' text-anchor='middle'%3E${encodeURIComponent(initials.toUpperCase())}%3C/text%3E%3C/svg%3E`

// #endregion

// #region Types

export type AvatarProps = JSX.IntrinsicElements["img"] & {
  variant?: "circle" | "rounded"
}

// #endregion
